var WY = WY || {}
/**
 * WY.SafeCookieHandling
 */
WY.CookieHandling = {
    /**
     * Settings for this component which can be overwritten for own use.
     *
     * Example: Override cookieLayerSelector
     *  WY.CookieHandling.settings.cookieLayerSelector = '#otherCookieLayerSelector'
     */
    settings: {
        cookieLayerSelector: '#cookieLayer',
        cookieSuffix: '_cookies',
        consentCookieName: 'cookieConsent',
        cookieConsentChangedEventName: 'cookieConsentChanged',
    },

    /**
     * Main settings for cookies which is used for handling all cookies.
     * All functions to enable, disable cookies or cookieGroups use this settings
     * to not use unwanted cookies.
     */
    cookieSettings: {},

    /**
     * This object will be used to save the state of the consentCookie.
     * All cookies and their group will be added to this property and
     * saved to a cookie after calling saveConsentCookie()
     */
    consentCookie: {},

    /**
     * Enables a group of cookies.
     *
     * A cookieGroup will be enabled by adding a cookie with a specific prefix.
     * The following pattern will be used for the cookieGroupName:
     * {groupname}{prefix}
     *
     * Example:
     *  Input:
     *   cookieGroupToEnableName: test
     *   prefix: '_cookies'
     *
     *  Built Cookie: test_cookies
     *
     * @param cookieGroupToEnableName
     */
    addCookieGroup: function (cookieGroupToEnableName) {
        if (!cookieGroupToEnableName) {
            return;
        }
        this.consentCookie[cookieGroupToEnableName] = {
            cookies: [],
            accepted: false
        };
    },

    /**
     * Enables a group of cookies.
     *
     * A cookieGroup will be enabled by adding a cookie with a specific prefix.
     * The following pattern will be used for the cookieGroupName:
     * {groupname}{prefix}
     *
     * Example:
     *  Input:
     *   cookieGroupToEnableName: test
     *   prefix: '_cookies'
     *
     *  Built Cookie: test_cookies
     *
     * @param cookieGroupToEnableName
     */
    acceptCookieGroup: function (cookieGroupToEnableName) {
        this.consentCookie[cookieGroupToEnableName].accepted = true;
    },

    /**
     * Disables a group of cookies.
     *
     * A cookieGroup will be removed and all cookies in the given group aswell.
     *
     * @param cookieGroupToDisableName
     */
    removeCookieGroup: function (cookieGroupToDisableName) {
        if (!cookieGroupToDisableName) {
            return;
        }
        delete this.consentCookie[cookieGroupToDisableName];
    },

    /**
     * Accept a cookie of a group.
     *
     * This functions searches the settings for a cookie name and if any group has been found
     * the cookieGroup will be updated with the cookie.
     *
     * @param cookieToAcceptName
     * @param groupName
     */
    acceptCookieInGroup: function (cookieToAcceptName, groupName) {
        if (!cookieToAcceptName || !groupName) {
            return;
        }

        if (!this.cookieSettings.hasOwnProperty(groupName)) {
            return;
        }

        this.consentCookie[groupName].cookies.push(cookieToAcceptName);
    },

    /**
     * Disables a cookie from a group.
     *
     * This functions searches the settings for a cookie name and if any group has been found
     * for the given cookie will be removed from the cookieGroup.
     *
     * @param cookieToDisableName
     * @param groupName
     */
    removeCookieInGroup: function (cookieToDisableName, groupName) {
        if (!cookieToDisableName || !groupName) {
            return;
        }

        if (!this.cookieSettings.hasOwnProperty(groupName)) {
            return;
        }

        var cookieGroup = this.consentCookie[groupName],
            indexOfCookie = cookieGroup.cookies.indexOf(cookieToDisableName);

        if (indexOfCookie !== -1) {
            this.consentCookie[groupName].cookies = cookieGroup.splice(indexOfCookie, 1);
        }
    },

    /**
     * Check if a cookieGroup is enabled.
     *
     * This function checks if a cookie with the cookieGroup exists.
     * If a cookie exists, then the group is enabled, otherwise it's not.
     *
     * @param cookieGroupName
     * @returns {boolean}
     */
    cookieGroupIsAccepted: function (cookieGroupName) {
        if (!this.consentCookie.hasOwnProperty(cookieGroupName)) {
            return false;
        }
        return this.consentCookie[cookieGroupName].accepted;
    },

    /**
     * Check if a cookie is enabled.
     *
     * This function checks if a cookie with the cookieGroup exists.
     * If a cookie exists, then the group is enabled, otherwise it's not.
     *
     * @param cookieName
     * @param cookieGroupName
     * @returns {boolean}
     */
    cookieInGroupIsAccepted: function (cookieName, cookieGroupName) {

        if (!this.consentCookie.hasOwnProperty(cookieGroupName)) {
            return false;
        }

        if (!this.consentCookie[cookieGroupName].hasOwnProperty('cookies')) {
            return false;
        }

        return this.consentCookie[cookieGroupName].cookies.indexOf(cookieName) !== -1;
    },

    /**
     * Check if a cookie is enabled.
     *
     * This function checks if a cookie exists, by iterating over all
     * cookieGroups.
     *
     * @param cookieName
     * @returns {boolean}
     */
    cookieIsAccepted: function (cookieName) {
        var cookieGroups = Object.keys(this.consentCookie);

        for (var i = 0; i < cookieGroups.length; i++) {
            if (this.cookieInGroupIsAccepted(cookieName, cookieGroups[i])) {
                return true;
            }
        }

        return false;
    },

    /**
     * Enables all cookies from a group.
     *
     * This function activates all cookies from a group by just using the cookieGroupName.
     * It will then enable all configured child-cookies.
     *
     * @param cookieGroupName
     */
    acceptAllCookiesFromGroup: function (cookieGroupName) {
        this.addCookieGroup(cookieGroupName);
        this.acceptCookieGroup(cookieGroupName);

        for (var cookieKey in this.cookieSettings[cookieGroupName].cookies) {
            if (!this.cookieSettings[cookieGroupName].cookies.hasOwnProperty(cookieKey)) {
                continue;
            }

            var cookieName = this.cookieSettings[cookieGroupName].cookies[cookieKey].cookieName;
            WY.CookieHandling.acceptCookieInGroup(cookieName, cookieGroupName);
        }
    },

    /**
     * This functions should be used, to set a cookie.
     * It will check if a cookie has been accepted.
     * The cookie will be added, when there is a consent regarding this cookie.
     *
     * @param cookieName
     * @param cookieValue
     * @param path
     * @param expireDays
     */
    tryAddCookie: function (cookieName, cookieValue, path, expireDays) {
        if (this.cookieIsAccepted(cookieName)) {
            this._setCookie(cookieName, cookieValue, expireDays);
        }
    },

    /**
     * Set a cookie.
     *
     * This functions adds directly a cookie with a given cookieName, cookieValue and lifetime in days.
     *
     * @param cookieName - cookie name
     * @param cookieValue - cookie value
     * @param expireDays - expiry in days
     */
    _setCookie: function (cookieName, cookieValue, expireDays) {
        var date = new Date();
        date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + date.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    },

    /**
     * Get a cookie
     *
     *
     * @param cookieName - cookie name
     * @returns string
     */
    _getCookie: function (cookieName) {
        var rawCookieName = cookieName + "=";
        var rawCookies = document.cookie.split(';');
        for (var i = 0; i < rawCookies.length; i++) {
            var cookie = rawCookies[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(rawCookieName) == 0) {
                return decodeURIComponent(cookie.substring(rawCookieName.length, cookie.length));
            }
        }
        return undefined;
    },

    /**
     * This function removes a specific cookie, by its name.
     * It also removes cookies from a domain to clean up safely from a domain.
     *
     * @param cookieName
     * @param domain
     */
    _removeCookie: function (cookieName, domain) {
        if (!domain) {
            domain = window.location.hostname;
        }
        document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=' + domain + '; path=/;';

        var firstIndexOfDot = domain.indexOf('.'),
            lastIndexOfDot = domain.lastIndexOf('.');

        if (firstIndexOfDot !== -1 && firstIndexOfDot !== lastIndexOfDot) {
            this._removeCookie(cookieName, domain.substr(domain.indexOf('.') + 1))
        }
    },

    /**
     * Removes all cookies which haven't been accepted.
     *
     * Iterates through all cookies found in `document.cookie` and checks if
     * each cookie is accepted and deletes them if not accepted.
     */
    removeUnacceptedCookie: function () {
        var rawCookies = document.cookie.split(';');
        for (var i = 0; i < rawCookies.length; i++) {
            var cookie = rawCookies[i];
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }

            var cookieName = decodeURIComponent(cookie.substr(0, cookie.indexOf('=')));

            if (!this.cookieIsAccepted(cookieName)) {
                this._removeCookie(cookieName);
            }
        }
    },

    /**
     * Saves the ConsentCookie-Object as a persistent cookie and triggers an event.
     */
    saveConsentCookie: function () {
        var previousCookieValue = this._getCookie(this.settings.consentCookieName);
        var newCookieValue = encodeURIComponent(JSON.stringify(this.consentCookie));

        this._setCookie(this.settings.consentCookieName, newCookieValue, this.getExpireDaysForTimeString(this.settings.consentCookieLifeTime));
        this._setCookie(this.settings.consentCookieAcceptedName, this.settings.consentCookieVersion, this.getExpireDaysForTimeString(this.settings.cookieConsentAcceptedLifetime));

        if (previousCookieValue !== newCookieValue) {
            var event;

            if (typeof window.CustomEvent === 'function') {
                event = new CustomEvent(this.settings.cookieConsentChangedEventName, {
                    detail: this.consentCookie
                });
            } else {
                event = document.createEvent('CustomEvent');
                event.initCustomEvent(this.settings.cookieConsentChangedEventName, true, true, this.consentCookie);
            }

            document.dispatchEvent(event);
        }
    },

    /**
     *
     * @param timeString
     * @returns {number}
     */
    getExpireDaysForTimeString: function (timeString) {
        var expireDays = 30;
        var months = 1;
        var years = 1;

        if (timeString === undefined) {
            return expireDays;
        }

        if (timeString.indexOf('months') !== -1) {
            months = timeString.replace(/(^\d+)(.+$)/i, '$1');
            expireDays = expireDays * months;
        }
        if (timeString.indexOf('years') !== -1) {
            years = timeString.replace(/(^\d+)(.+$)/i, '$1');
            expireDays = expireDays * months * (12 * years);
        }

        return expireDays;
    },

    /**
     * Updates the lifetime for a cookie, if the cookie by the given cookieName is outdated
     *
     * @param cookieName
     */
    updateCookieLifetimeForCookie: function(cookieName){
        var updateLifetimeRequest = new XMLHttpRequest();
        updateLifetimeRequest.open('GET', '/cookie-services/cookies/update-lifetime?cookieName=' + cookieName);
        updateLifetimeRequest.send(null);
    },

    /**
     * Updates the lifetime for all cookies
     */
    updateCookieLifetimeForAllCookies: function(){
        var updateLifetimeRequest = new XMLHttpRequest();
        updateLifetimeRequest.open('GET', '/cookie-services/cookies/update-lifetimes');
        updateLifetimeRequest.send(null);
    },

    init: function () {
        if(document.cookieHandling === undefined) {
            return;
        }

        var savedCookie = this._getCookie(this.settings.consentCookieName) || '{}';
        this.consentCookie = JSON.parse(savedCookie);
        this.cookieSettings = JSON.parse(document.cookieHandling.cookieSettings);
        this.settings.consentCookieName = document.cookieHandling.consentCookieName;
        this.settings.consentCookieLifeTime = document.cookieHandling.consentCookieLifeTime;
        this.settings.consentCookieAcceptedName = document.cookieHandling.consentCookieAcceptedName;
        this.settings.consentCookieAcceptedLifetime = document.cookieHandling.consentCookieAcceptedLifetime;
        this.settings.consentCookieVersion = document.cookieHandling.consentCookieVersion;
    }
}

WY.CookieLayer = {

    /**
     * General settings for cookie attributes used by this component.
     */
    settings: {
        cookieSettingsDataAttribute: 'cookie-groups',
        cookieName: 'cookieConsentAccepted',
    },

    /**
     * CookieSettings which are set dynamically from the template
     * via data (WY.CookieHandling.settings.cookieSettingsDataAttribute)
     */
    cookieSettings: {},

    /**
     * All necessary selectors and elements for the cookieLayer.
     */
    elements: {
        cookieLayerSelector: '#cookieLayer',
        cookieLayerElement: undefined,
        cookieModalSelector: '#modal-cookie',
        cookieModalElement: undefined,
        cookieFormSelector: '#cookieForm',
        cookieFormElement: undefined,
        saveSelectedCookies: '#saveCookies',
        saveAllCookies: '#saveAllCookies',
        changeCookiesSelector: '#changeCookies',
    },

    /**
     * Options which are necessary during the lifetime of this component.
     */
    options: {
        cookieLayerIsHideAble: false,
        cookieLayerIsDisabled: false,
    },

    /**
     * Initialized the cookie form.
     *
     * This function initializes all parts of the cookie form, to add all necessary events to
     * cookie groups and their children and also set their status.
     * After that the cookie layer is able to be closed and will be hidden.
     */
    initCookieForm: function () {
        this.initCookieGroupCheckboxes()
        this.initChildCookieCheckboxes()
        this.initSaveSelectionButton()
        this.initSaveAllButton()
    },

    /**
     *
     */
    initSaveSelectionButton: function (){
        const scope = this
        const saveSelectedCookiesButton = document.querySelector(this.elements.saveSelectedCookies)

        saveSelectedCookiesButton.addEventListener('click', function (event) {
            event.preventDefault()

            const groupCheckboxes = [].slice.call(document.querySelectorAll(
                'input.group-check'
            ))

            groupCheckboxes.forEach(function (groupCheckbox) {
                const cookieGroupName = groupCheckbox.dataset.group
                WY.CookieHandling.addCookieGroup(cookieGroupName)

                if (groupCheckbox.checked) {
                    WY.CookieHandling.acceptCookieGroup(cookieGroupName)
                    WY.CookieHandling.acceptAllCookiesFromGroup(cookieGroupName)
                }

                else {
                    const childCookies = [].slice.call(document.querySelectorAll(
                        '.single-check[data-group="' + cookieGroupName + '"]'
                    ))

                    childCookies.forEach(function (childCookie) {
                        const cookieName = childCookie.dataset['cookie-name']

                        if (childCookie.checked) {
                            WY.CookieHandling.acceptCookieInGroup(cookieName, cookieGroupName)
                        } else {
                            WY.CookieHandling.removeCookieInGroup(cookieName, cookieGroupName)
                        }
                    })
                }
            })

            scope.submitCookieForm()
        })

    },

    /**
     *
     */
    initSaveAllButton: function () {
        const scope = this
        const saveAllCookiesButton = document.querySelector(this.elements.saveAllCookies)

        saveAllCookiesButton.addEventListener('click', function (event) {
            event.preventDefault()

            for (let groupName in scope.cookieSettings) {
                WY.CookieHandling.acceptAllCookiesFromGroup(groupName)
            }

            scope.submitCookieForm()
        })
    },

    /**
     * Submits the CookieForm
     *
     * Saves the state of the consentCookie and adds a corresponding cookie.
     * Reinitialize the form and hides the cookieLayer.
     */
    submitCookieForm: function () {
        this.options.cookieLayerIsHideAble = true

        WY.CookieHandling.saveConsentCookie()
        WY.CookieHandling.removeUnacceptedCookie()

        this.hideCookieLayer()
    },

    /**
     * Initializes the cookie changer.
     *
     * The cookie changer is a simple element which will be enhanced with a click event to trigger
     * the modal open event.
     */
    initCookieChanger: function () {
        const scope = this
        const changeCookiesButton = document.querySelector(
            this.elements.changeCookiesSelector
        )

        if (changeCookiesButton) {
            changeCookiesButton.addEventListener('click', function() {
                scope.openCookieLayer()
            })
        }
    },

    /**
     * Initializes cookie group checkboxes.
     *
     * Adds an event to all group checkboxes which automatically set the status of the cookie group to
     * its child cookies to enable or disable a cookie group at the same time.
     */
    initCookieGroupCheckboxes: function () {
        const scope = this

        const cookieGroups = [].slice.call(document.querySelectorAll(
            this.elements.cookieLayerSelector + ' input.group-check')
        )

        cookieGroups.forEach(function(cookieGroup) {
            if (!cookieGroup.checked) {
                cookieGroup.checked = WY.CookieHandling.cookieGroupIsAccepted(
                    cookieGroup.dataset.group
                )
            }

            cookieGroup.addEventListener('click', function() {
                const groupChecked = cookieGroup.checked
                const childElements = [].slice.call(document.querySelectorAll(
                    scope.elements.cookieLayerSelector +
                    ' input.single-check[data-group="' +
                    cookieGroup.dataset.group + '"]'
                ))

                childElements.forEach(function (childElement) {
                    childElement.checked = groupChecked
                })
            })
        })
    },

    /**
     * Initializes child cookie checkboxes.
     *
     * Every child cookie checkbox gets an event which activates their parent cookie group, if the
     * cookie has been activated / checked.
     */
    initChildCookieCheckboxes: function () {
        const childCookieCheckboxes = [].slice.call(document.querySelectorAll(
            this.elements.cookieLayerSelector + ' input.single-check'
        ))

        childCookieCheckboxes.forEach(function (childCookieCheckbox) {
            if (!childCookieCheckbox.checked) {
                childCookieCheckbox.checked = WY.CookieHandling.cookieIsAccepted(
                    childCookieCheckbox.dataset['cookie-name']
                )
            }

            childCookieCheckbox.addEventListener('click', function() {
                const parentCookieCheckbox = document.querySelector(
                    '.group-check[data-group="'+
                    childCookieCheckbox.dataset.group +'"]'
                )
                parentCookieCheckbox.checked = false
            })
        })
    },

    /**
     * Initializes the cookieLayer modal.
     *
     * Overrides the hide event of the modal to make the cookieLayer only closeable if
     * the consent cookie has been set.
     */
    initCookieModal: function () {
        const scope = this

        if (WY.CookieHandling._getCookie(this.settings.cookieName)) {
            this.options.cookieLayerIsHideAble = true
        }

        const cookieModal = document.querySelector(
            this.elements.cookieModalSelector
        )

        cookieModal.addEventListener('hide.bs.modal', function (event) {
            if (!scope.options.cookieLayerIsHideAble) {
                event.preventDefault()
            }
        })
    },

    /**
     * Initializes toggle buttons.
     *
     * These buttons show and hide detail-cookies.
     */
    initCookieDetailToggle: function () {
        const showDetailsToggles = [].slice.call(document.querySelectorAll('.detail-anchor-show'))
        const hideDetailsToggles = [].slice.call(document.querySelectorAll('.detail-anchor-hide'))

        const showDetailsCallback = function (event) {
            event.preventDefault()
            event.target.hidden = true

            const detailPanel = document.getElementById(event.target.dataset.control)
            detailPanel.classList.remove('collapse')
            detailPanel.classList.add('collapsed')

            const hideDetails = document.querySelector(
                '.detail-anchor-hide[data-control=' + event.target.dataset.control + ']'
            )

            hideDetails.hidden = false
        }

        const hideDetailsCallback = function (event) {
            event.preventDefault()
            event.target.hidden = true

            const detailPanel = document.getElementById(event.target.dataset.control)
            detailPanel.classList.remove('collapsed')
            detailPanel.classList.add('collapse')

            const showDetails = document.querySelector(
                '.detail-anchor-show[data-control=' + event.target.dataset.control + ']'
            )

            showDetails.hidden = false
        }

        showDetailsToggles.forEach(function (showDetailsToggle) {
            showDetailsToggle.addEventListener('click', showDetailsCallback)
        })

        hideDetailsToggles.forEach(function (hideDetailsToggle) {
            hideDetailsToggle.addEventListener('click', hideDetailsCallback)
        })
    },

    /**
     * Helper function to show the cookieLayer modal.
     *
     * A modal won't be opened in the following cases:
     *  - if the cookielayer has been disabled for the page (for example on privacy pages)
     *  - in Neos backend
     *  - the consentCookie has been set
     *
     */
    showCookieLayer: function () {
        if (this.options.cookieLayerIsDisabled === true || this.options.cookieLayerIsDisabled === 'true') {
            return
        }

        if (WY.CookieHandling._getCookie(this.settings.cookieName) !== document.cookieHandling.consentCookieVersion) {
            this.openCookieLayer()
        }
    },

    /**
     * open the cookie layer without checking for the current state
     */
    openCookieLayer: function () {
        document
            .querySelector(this.elements.cookieModalSelector)
            .classList.remove('hidden')
    },

    /**
     * Helper function to hide the cookieLayer modal.
     */
    hideCookieLayer: function () {
        document
            .querySelector(this.elements.cookieModalSelector)
            .classList.add('hidden')
    },

    /**
     * This function watches for changes on the ConsentCookie,
     * to make sure that the cookie-layer is displaying a correct state of the consentCookie.
     */
    observeConsentCookieChanges: function () {
        var scope = this

        document.addEventListener(WY.CookieHandling.settings.cookieConsentChangedEventName, function () {
            scope.initCookieGroupCheckboxes()
            scope.initChildCookieCheckboxes()
        });
    },

    /**
     * Initializes CookieLayer.
     */
    init: function () {
        if(document.cookieHandling === undefined) {
            return
        }

        this.elements.cookieLayerElement = document.querySelector(this.elements.cookieLayerSelector)
        this.elements.cookieModalElement = document.querySelector(this.elements.cookieModalSelector)
        this.elements.cookieFormElement = document.querySelector(this.elements.cookieFormSelector)

        if (this.elements.cookieLayerElement) {
            WY.CookieHandling.init()

            this.cookieSettings = JSON.parse(document.cookieHandling.cookieSettings)
            this.options.cookieLayerIsDisabled = document.cookieHandling.cookieLayerDisabled
            this.options.isInBackend = false

            this.initCookieModal()
            this.initCookieForm()
            this.initCookieDetailToggle()
            this.initCookieChanger()

            this.showCookieLayer()
            this.observeConsentCookieChanges()
        }
    }
}

window.addEventListener('load', function() {
    WY.CookieLayer.init()
    function initCookieListenerForScript() {
        document.addEventListener('cookieConsentChanged', function (data) {
            if (WY.CookieHandling.cookieIsAccepted('google')) {
                enableGoogleAnalyticsMeasurement();
            } else {
                disableGoogleAnalyticsMeasurement();
            }
        });
    }
    function enableGoogleAnalyticsMeasurement() {
        window['ga-disable-' + window.gtmId] = false;
        // PREVENT multiple gtm.js pushes
        if (window.ga === undefined) {
            initGoogleAnalyticsScript();
        }
    }
    function initGoogleAnalyticsScript() {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', window.gtmId);
    }
    function disableGoogleAnalyticsMeasurement() {
        window['ga-disable-' + window.gtmId] = true;
    }
    initCookieListenerForScript();
    if (WY.CookieHandling.cookieIsAccepted('google')) {
        enableGoogleAnalyticsMeasurement();
    } else {
        disableGoogleAnalyticsMeasurement();
    }
})
